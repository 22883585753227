<template>
  <div class="container" v-cloak>
    <!-- 贷款申请已通过，等待资方电话复审 -->
    <div class="top-con u-text-center">
      <img src="../assets/images/img_tel.png" />
      <h4>贷款申请已通过，等待资方电话复审</h4>
      <p>首次借款需资方客户经理电话联系您确认相关信息, 请注意接听!</p>
    </div>

    <!-- 机构客服信息 -->
    <div class="mechanism-con">
      <div class="line" />
      <div class="mechanism-info">
        <div class="linear-gradient" />
        <!-- 机构信息 -->
        <div class="u-flex mechanism-info-div pr">
          <!-- 未匹配上机构 -->
          <div
            class="u-flex-1 u-text-center"
            v-if="applyId === '0' || !productName"
          >
            <div class="origan-desc">— 请注意接听该机构电话 —</div>
            <div class="u-flex u-row-between u-col-center">
              <p
                class="u-flex-1 origan-right"
                v-if="applyId === '0' || !productName"
              >
                正在匹配中...
              </p>
            </div>
          </div>

          <!-- 匹配上机构 -->
          <template v-else>
            <img
              :src="
                productLogo ? productLogo : '../assets/images/img_mechanism.png'
              "
            />
            <div class="u-flex-col u-flex-1">
              <div class="u-flex u-row-between">
                <h4>{{ productName }}</h4>
                <span>{{ `授信金额：${loanAmount}元` }}</span>
              </div>
              <p>资方将于30分钟内联系您（09:00-18:00)</p>
            </div>
          </template>
        </div>

        <!-- 客服信息(有机构不一定有客服电话) -->
        <div
          class="customer-service-div"
          v-if="!(applyId === '0' || !productName)"
        >
          <template v-if="callMobile">
            <p>将由该客户经理为您服务</p>
            <div class="u-flex u-row-between">
              <div class="u-flex">
                <img src="../assets/images/icon_avatar.png" />
                <h5>{{ callMobile }}</h5>
              </div>
              <span>请注意接听该号码来电</span>
            </div>
          </template>
          <p v-else class="u-text-center call-mobile-tips">
            *为保障电话复审通过，请注意接听来电
          </p>
        </div>

        <!-- 下载APP查看实时放款进度 -->
        <div class="pr">
          <template v-if="isRedirectProductsPage === 0">
            <van-button
              class="download-app-btn"
              type="default"
              @click="handleDownload"
              block
            >
              <span class="span">下载APP</span>查看实时放款进度
            </van-button>
            <small class="pa">30天免息券*2已发放，下载使用</small>
          </template>

          <!-- 我知道了 -->
          <van-button
            v-else
            class="download-app-btn"
            type="default"
            @click="handleIGotIt"
            block
          >
            我知道了
          </van-button>
        </div>

        <!-- 贷款流程 -->
        <div class="loan-process-con">
          <h4>贷款流程</h4>
          <ul class="u-flex u-text-center">
            <li class="loan-process-li u-flex-1">
              <img src="../assets/images/icon_fkxtsh.png" />
              <h6>风控系统审核</h6>
              <div class="u-flex u-row-center">
                <img
                  class="icon-img"
                  src="../assets/images/icon_check_mark.png"
                />
                <span class="green">已通过</span>
              </div>
              <p>可放款</p>
            </li>
            <li class="line pr">
              <img class="pa" src="../assets/images/tl4_step_line.png" />
            </li>
            <li class="loan-process-li u-flex-1">
              <img src="../assets/images/icon_dhfs.png" />
              <h6>电话复审</h6>
              <div class="u-flex u-row-center">
                <img class="icon-img" src="../assets/images/icon_jxz.png" />
                <span class="yellow">进行中</span>
              </div>
              <p>注意接听</p>
            </li>
            <li class="line pr">
              <img class="pa" src="../assets/images/tl4_step_line.png" />
            </li>
            <li class="loan-process-li u-flex-1">
              <img src="../assets/images/icon_appqk.png" />
              <h6>APP取款</h6>
              <div class="u-flex u-row-center">
                <img class="icon-img" src="../assets/images/icon_dddhfs.png" />
                <span class="default">等待电话复审</span>
              </div>
              <p>注意接听</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 产品列表 -->
    <div v-if="productInfoObj15" class="product-elem">
      <h5 class="product-elem-title">
        根据您的信用情况,您还可以快速在以下产品获得贷款
      </h5>

      <!-- 产品展示 -->
      <div class="product-display">
        <div class="u-flex" @click="handleProductClick(productInfoObj15, 15)">
          <img
            class="product-img"
            :src="
              productInfoObj15.imgUrl
                ? productInfoObj15.imgUrl
                : '../assets/images/trial_success.png'
            "
          />
          <div class="product-title u-flex-col u-row-between">
            <div class="u-flex">
              <h4>{{ productInfoObj15.productName }}</h4>
              <van-tag
                v-for="(item, index) in productInfoObj15.tagList"
                :key="index"
                color="rgba(53,99,250,0.1)"
                text-color="#3563FA"
              >
                {{ item }}
              </van-tag>
            </div>
            <p class="credit-for-you">
              为您授信
              <span>
                {{ productInfoObj15.creditAmount }}
              </span>
              元贷款额度
            </p>
          </div>
        </div>
        <ul class="u-flex u-row-between product-display-ul u-text-left">
          <li class="u-flex-col">
            <p>{{ `${productInfoObj15.timeLimitLoanRate}%` }}</p>
            <small>限时综合年化</small>
          </li>
          <li class="u-flex-col">
            <p>{{ productInfoObj15.loanPeriod }}</p>
            <small>最高可借期限(月)</small>
          </li>
          <li class="u-flex-col">
            <p>{{ productInfoObj15.maxAmount }}</p>
            <small>最高借款金额 (元)</small>
          </li>
        </ul>
        <p class="originate-loan">
          已成功向
          <span>{{ productInfoObj15.loanNumber }}</span>
          人发起贷款
        </p>
      </div>
    </div>

    <!-- 产品弹窗 -->
    <template v-if="productInfoObj16">
      <van-popup
        v-model="show"
        class="product-layer u-flex-col u-col-center"
        round
        :close-on-click-overlay="false"
      >
        <h4>为您匹配到合适的贷款产品</h4>
        <img :src="productInfoObj16.imgUrl" />
        <h5>{{ productInfoObj16.productName }}</h5>
        <p>
          您最高可借
          <span>{{ productInfoObj16.maxAmount }}</span>
        </p>
        <div
          class="btn-now u-text-center"
          @click="handleProductClick(productInfoObj16, 16)"
        >
          立即申请
        </div>
        <div class="btn-not u-text-center" @click="show = false">先不用</div>
      </van-popup>
    </template>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "TrialSuccessTl3",
  data() {
    return {
      source: this.$route.query.source, // 渠道标识
      loanAmount: this.$route.query.loanAmount, // 金额
      applyId: this.$route.query.applyId, // 进件申请id(0为不成功，无需调用接口查询机构信息,不为0需要查询)
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程1是 0否
      productLogo: "", // 机构ICON
      productName: "", // 机构名称
      callMobile: "", // 客服电话
      downAppUrl: undefined, // 下载地址
      showPop: false, // 分享蒙层状态
      show: false, // 产品弹窗
      productInfoObj15: null,
      productInfoObj16: null,
    };
  },
  mounted() {
    let isWeixin = this.isWeixin();
    if (isWeixin) {
      this.showPop = true;
    }

    // 如果进件申请id不为0则查询机构信息
    if (this.applyId !== "0") {
      this.getMechanismInfo();
    }

    this.getDownloadUrl();

    // 获取页面底部的产品详情信息
    this.getProductInfo(15);

    // 获取弹窗内部产品详情信息
    this.getProductInfo(16);

    // 添加浏览器浏览记录(贷超流程增加这个历史记录，非贷超流程不需要增加这个历史记录)
    // if (this.isRedirectProductsPage === 1) {
    //   this.addHistory();
    // }
  },
  methods: {
    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          // 有弹窗内部推荐产品的时候才允许弹窗
          if (this.productInfoObj16) {
            this.show = true;
          }

          setTimeout(() => {
            window.location.href = this.downAppUrl;
          }, 50);
        }
      }
    },

    // 我知道了按钮点击
    handleIGotIt() {
      var timestamp = new Date().getTime();
      window.location.href = `/productList.html?source=${this.source}&tsp=${timestamp}`;
    },

    // 获取机构信息
    getMechanismInfo() {
      API_Common.getMechanismInfo(this.applyId).then((res) => {
        if (res.code === 200) {
          // 进件是否推送成功;1:成功(会返回对应的产品信息)、0:失败(不会返回产品信息)
          if (res.data.hasPushSuccess === 1) {
            this.productName = res.data.productName;
            this.productLogo = res.data.productLogo;
            this.callMobile = res.data.callMobile;
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.source, true).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },

    // 获取产品信息
    getProductInfo(showPage) {
      // showPage: 15,H5进件完成页(用于页面底部的产品详情查询),16,投放页完成下载APP(用于页面弹窗的产品详情查询),
      API_Common.getProductInfo({ showPage }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this[`productInfoObj${showPage}`] = res.data;
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    // 监听产品点击
    handleProductClick(obj, showPage) {
      // 埋点
      API_Common.buried({
        productId: obj.productId,
        showPage,
      }).then((res) => {
        if (200 === res.code) {
          setTimeout(() => {
            var timestamp = new Date().getTime();
            window.location.href = obj.linkUrl + "?tsp=" + timestamp;
          }, 500);
        }
      });
    },

    // 给浏览器的访问记录添加一条此页面的访问记录
    addHistory() {
      window.history.pushState(null, null, "productList.html");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #f2f5fa;
  height: 100vh;
  overflow: auto;

  .top-con {
    padding: 0.46rem 1.08rem 1.04rem;
    background: #3563fa;
    font-size: 0.24rem;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: normal;
    color: #fff;

    > img {
      width: 1.46rem;
      height: 1.18rem;
    }

    > h4 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
      margin: 0.32rem 0 0.1rem;
    }
  }

  .mechanism-con {
    margin-top: -0.74rem;
    padding: 0 0.16rem;

    > .line {
      height: 0.2rem;
      background: #0a4fcc;
      border-radius: 0.1rem;
    }

    > .mechanism-info {
      margin-top: -0.1rem;
      padding: 0 0.24rem;

      .linear-gradient {
        height: 0.36rem;
        background: linear-gradient(180deg, #718dea, #ffffff);
      }

      .mechanism-info-div {
        background: #ffffff;
        padding: 0.22rem 0.3rem 0.36rem;
        font-size: 0.22rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #838f9f;

        &::after {
          position: absolute;
          box-sizing: border-box;
          content: " ";
          pointer-events: none;
          top: -50%;
          right: -42%;
          bottom: -50%;
          left: -42%;
          border-bottom: 0.02rem dashed #eee;
          transform: scale(0.5);
        }

        > img {
          width: 0.8rem;
          height: 0.8rem;
          margin-right: 0.16rem;
          border-radius: 50%;
        }

        h4 {
          font-size: 0.32rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: normal;
          color: #192337;
        }

        span {
          font-size: 0.24rem;
          color: #3663fa;
        }

        .origan-desc {
          font-size: 0.24rem;
          font-family: PingFangSC, PingFangSC-Thin;
          font-weight: normal;
          color: #111c31;
          line-height: 0.34rem;
          margin-bottom: 0.4rem;
        }

        .origan-right {
          font-size: 0.28rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #111c31;
        }
      }

      .customer-service-div {
        background: #ffffff;
        border-radius: 0 0 0.24rem 0.24rem;
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #838f9f;
        padding: 0.3rem 0.3rem 0.24rem;

        .call-mobile-tips {
          color: #f03d23;
        }

        > div {
          margin-top: 0.24rem;
        }

        img {
          width: 0.6rem;
          height: 0.6rem;
          margin-right: 0.14rem;
          border-radius: 50%;
        }

        h5 {
          font-size: 0.28rem;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: bold;
          color: #192337;
        }

        span {
          color: #f03d23;
        }
      }

      .loan-process-con {
        margin-top: 0.24rem;
        background: #ffffff;
        border-radius: 0.24rem;
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        padding: 0.3rem 0.3rem 0.24rem;

        > h4 {
          font-size: 0.32rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: normal;
          color: #192337;
        }

        ul {
          align-items: stretch;
          margin-top: 0.28rem;
        }

        .loan-process-li {
          > img {
            width: 0.72rem;
            height: 0.72rem;
            border-radius: 50%;
          }

          .icon-img {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            margin-right: 0.06rem;
          }

          h6 {
            font-size: 0.28rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: normal;
            color: #192337;
            margin: 0.2rem 0 0.08rem;
          }

          span.green {
            color: #52c41a;
          }

          span.yellow {
            color: #f5a623;
          }

          span.default {
            color: #868e9e;
          }

          p {
            margin-top: 0.08rem;
          }
        }

        .line {
          width: 0;
          padding-top: 0.3rem;
          > img {
            width: 0.84rem;
            height: 0.08rem;
            right: -0.4rem;
            top: 0.3rem;
          }
        }
      }

      .download-app-btn {
        margin-top: 0.48rem;
        font-size: 0.32rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #fff;
        height: 0.84rem;
        background: #3563fa;
        border-radius: 0.48rem;

        .span {
          font-weight: bold;
        }

        + small {
          top: -0.3rem;
          right: 0;
          display: block;
          background: #f03d23;
          border: 0.02rem solid #ffffff;
          border-radius: 2rem 2rem 2rem 0;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #ffffff;
          padding: 0.02rem 0.16rem;
        }
      }
    }
  }

  .product-elem {
    margin: 0.4rem;

    .product-elem-title {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #111c31;
    }

    .product-display {
      background: #fff;
      border-radius: 0.24rem;
      margin-top: 0.24rem;
      padding: 0.3rem;

      > div {
        padding-bottom: 0.24rem;
        border-bottom: 0.02rem solid #e6e7ee;
      }

      .product-img {
        width: 0.96rem;
        height: 0.96rem;
        border-radius: 0.2rem;
        margin-right: 0.24rem;
      }

      .product-title {
        font-size: 0.32rem;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: bold;
        color: #111c31;

        .van-tag {
          margin-left: 0.1rem;
          padding: 0.02rem 0.1rem;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          margin-left: 0.12rem;
        }
      }

      .credit-for-you {
        margin-top: 0.05rem;
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #868e9e;
        > span {
          color: #f03d23;
        }
      }

      .product-display-ul {
        margin: 0.24rem 0 0.4rem;

        p {
          font-size: 0.4rem;
          font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
          font-weight: normal;
          color: #111c31;
        }

        small {
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          color: #868e9e;
        }
      }

      .originate-loan {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #868e9e;
        > span {
          color: #f03d23;
        }
      }
    }
  }

  .product-layer {
    width: calc(100% - 1.52rem);
    padding: 0.36rem 0.46rem;
    > h4 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #111c31;
    }

    > img {
      margin: 0.48rem 0 0.16rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.2rem;
    }

    > h5 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #111c31;
    }

    > p {
      margin: 0.16rem 0 0.3rem;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #111c31;
      > span {
        color: #fb5e1e;
      }
    }

    > div {
      width: 100%;
      height: 0.96rem;
      line-height: 0.96rem;
      border-radius: 0.48rem;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
    }

    > .btn-now {
      background: #3563fa;
      color: #ffffff;
    }

    > .btn-not {
      color: #868e9e;
    }
  }
}
</style>
